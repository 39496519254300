<template>
  <el-dialog
    ref="dialog"
    :title="editId ? '编辑规则' : '新增规则'"
    :model-value="dialogVisible"
    :before-close="handleClose"
    width="40%"
    :close-on-click-modal="false"
  >
    <el-form :rules="rules" ref="form" :model="ruleForm" label-width="100px">
      <el-form-item label="规则名称" prop="name">
        <el-input type="text" v-model="ruleForm.name" clearable></el-input>
      </el-form-item>
      <el-form-item label="加密盐" prop="salt">
        <el-input type="text" v-model="ruleForm.salt" clearable></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-switch
          v-model="ruleForm.status"
          active-text="启用"
          inactive-text="禁用"
          :active-value="1"
          :inactive-value="0"
        ></el-switch>
        <div class="tips-red ml-20">禁用规则时所属活动白名单校验不生效</div>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleConfirm" :disabled="loading">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ref, getCurrentInstance } from 'vue'
import { ElMessage } from 'element-plus/lib/components'
import util from '@/lin/util/util'
import whiteModel from '@/model/white-list'

export default {
  emits: ['confirm'],
  setup() {
    const dialogVisible = ref(false)
    const form = ref(null)
    const ruleForm = ref({})
    const editId = ref()
    const loading = ref(false)
    const ctx = getCurrentInstance()

    const init = item => {
      if (item.id) {
        editId.value = item.id
        ruleForm.value = JSON.parse(JSON.stringify(item))
      } else {
        editId.value = null
        ruleForm.value = {
          status: 1
        }
      }
      dialogVisible.value = true
    }

    // 确认修改、新增
    const handleConfirm = () => {
      form.value.validate(async valid => {
        console.log(ruleForm.value)
        if (valid) {
          let res
          const submitForm = {
            name: ruleForm.value.name,
            salt: ruleForm.value.salt,
            status: ruleForm.value.status,
          }
          try {
            loading.value = true
            if (!editId.value) res = await whiteModel.addWhiteListRule(submitForm)
            else res = await whiteModel.editWhiteListRule(editId.value, submitForm)
            ElMessage.success(res.message)
            ctx.emit('confirm')
            handleClose()
          } finally {
            loading.value = false
          }
        }
      })
    }

    // 关闭弹窗
    const handleClose = done => {
      form.value.resetFields()
      dialogVisible.value = false
      if (typeof done === 'function') done()
    }

    // 定义验证规则
    const getRules = () => {
      const checkInfo = (rule, value, callback) => {
        if (!value || value.length == 0) {
          callback(new Error('请将信息补充完整'))
        }
        // else {
        //   switch (rule.field) {
        //     case 'name':
        //       if (!util.checkLength(value, 2)) callback(new Error('至少输入2个字符'))
        //       break
        //     case 'salt':
        //       if (!util.checkLength(value, 6)) callback(new Error('至少输入6个字符'))
        //       break
        //     default:
        //       callback()
        //       break
        //   }
        // }
        callback()
      }
      const rules = {
        name: [{ validator: checkInfo, trigger: 'blur', required: true }],
        salt: [{ validator: checkInfo, trigger: 'blur', required: true }],
        status: [{ message: '请选择状态', trigger: 'blur', required: true }],
      }
      return { rules }
    }

    const { rules } = getRules()

    return {
      rules,
      form,
      ruleForm,
      editId,
      loading,
      dialogVisible,
      handleConfirm,
      handleClose,
      getRules,
      init,
    }
  },
}
</script>

<style></style>
